import React, { useState, useEffect } from 'react'
import Sidebar from '../Utilities/Sidebar'
import HeaderInner from '../Utilities/HeaderInner'
import Form from '../Utilities/Form'
import UpArrow from '../Utilities/UpArrow'
import InsideHeading from '../Utilities/InsideHeading'
import FooterInner from '../Utilities/FooterInner'
import ProductZoom from '../Utilities/ProductZoom'

const Qmarco300 = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  }, []);

  const [display, setdisplay] = useState("none");
  const [productzoom, setproductzoom] = useState("none");

  const [image, setImage] = useState("https://res.cloudinary.com/ddwk3hbiu/image/upload/v1704540449/Veinosil-Forte/Veinosil%20Tablet/Product/4_pqzsde.webp");

  const showModal = () => {
    setdisplay("block");
  }

  const close = () => {
    setdisplay("none");
  }

  const zoom = () => {
    setproductzoom("block");
  }

  const closezoom = () => {
    setproductzoom("none");
  }

  const handleClick = (event) => {
    const src = event.target.src;
    setImage(src);
  }


  return (
    <div>
      <Form show={display} close={close} />
      <ProductZoom show={productzoom} close={closezoom} img={image} />
      <UpArrow />
      <div id='overview'></div>
      <HeaderInner />
      <div className="main">
        <Sidebar howitworks="none" storage="none" />

        <div className="main-body">
          {/* Overview */}
          <div className="overview">
            <div className="overview-img">
              <div className="overview-main">
                <img src={image} alt="Product" />
                <svg id="Capa_1" onClick={zoom} enable-background="new 0 0 461.363 461.363" height="512" viewBox="0 0 461.363 461.363" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m185.594 371.221c41.282.054 81.385-13.769 113.866-39.248l122.777 122.777c9.172 8.858 23.787 8.604 32.645-.568 8.641-8.947 8.641-23.131 0-32.077l-122.777-122.777c62.784-80.96 48.05-197.488-32.91-260.272s-197.487-48.051-260.272 32.909-48.05 197.488 32.91 260.272c32.548 25.241 72.572 38.932 113.761 38.915zm-98.652-284.342c54.484-54.485 142.82-54.486 197.305-.002s54.486 142.82.002 197.305-142.82 54.486-197.305.002c-.001-.001-.001-.001-.002-.002-54.484-53.706-55.113-141.412-1.407-195.896.466-.472.935-.941 1.407-1.407z" /><path d="m116.332 208.618h46.174v46.174c0 12.751 10.336 23.087 23.087 23.087s23.087-10.336 23.087-23.087v-46.174h46.174c12.751 0 23.087-10.336 23.087-23.087s-10.336-23.087-23.087-23.087h-46.174v-46.174c0-12.751-10.336-23.087-23.087-23.087s-23.087 10.336-23.087 23.087v46.174h-46.174c-12.751 0-23.087 10.336-23.087 23.087s10.336 23.087 23.087 23.087z" /></g></svg>
              </div>

              <div className="overview-img-content">
                <img id='img-1' onClick={handleClick} src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1704540449/Veinosil-Forte/Veinosil%20Tablet/Product/4_pqzsde.webp" alt="1" />
                <img id='img-2' onClick={handleClick} src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1704540450/Veinosil-Forte/Veinosil%20Tablet/Product/2_jrodkt.webp" alt="2" />
                <img id='img-3' onClick={handleClick} src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1704540449/Veinosil-Forte/Veinosil%20Tablet/Product/3_jjme3l.webp" alt="3" />
                <img id='img-4' onClick={handleClick} src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1704540450/Veinosil-Forte/Veinosil%20Tablet/Product/1_ot37uj.webp" alt="4" />
              </div>
            </div>
            <div className="overview-text">
              <h3>Veinosil -Forte Tablet</h3>
              {/* <p className='tagline'>An assurance of Active Energy for whole body</p> */}
              {/* <h6>Key Ingredients</h6> */}
              <p className='content'>Veinosil a pharmaceutical formulation designed to address various vascular conditions.
                Calcium Dobesilate 500mg, which plays a crucial role in treating piles and varicose veins by reinforcing the walls of small vessels, reducing leakage, and enhancing blood flow through the reduction of blood viscosity. Additionally, it serves as an effective adjuvant therapy for patients with venous ulcers and stasis dermatitis, demonstrating a notable absence of significant side-effects.
              </p>
              <button onClick={showModal}>
                Buy Now
                &emsp;
                <svg id="bold" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><circle cx="10.5" cy="22.5" r="1.5" /><circle cx="18.5" cy="22.5" r="1.5" /><path d="m24 6.5c0 3.584-2.916 6.5-6.5 6.5s-6.5-2.916-6.5-6.5 2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5zm-3.342-3.002c-.416-.364-1.047-.32-1.411.094l-2.796 3.196-1.244-1.244c-.391-.391-1.023-.391-1.414 0s-.391 1.023 0 1.414l2 2c.409.409 1.079.387 1.459-.048l3.5-4c.364-.417.322-1.049-.094-1.412z" /><path d="m9 6.5c0-.169.015-.334.025-.5h-2.666l-.38-1.806c-.266-1.26-1.392-2.178-2.679-2.183l-2.547-.011c-.001 0-.002 0-.003 0-.413 0-.748.333-.75.747s.333.751.747.753l2.546.011c.585.002 1.097.42 1.218.992l.505 2.401 1.81 8.596h-.576c-1.241 0-2.25 1.009-2.25 2.25s1.009 2.25 2.25 2.25h15c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-15c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.499.001 13.5c.354 0 .661-.249.734-.596l.665-3.157c-1.431 1.095-3.213 1.753-5.149 1.753-4.687 0-8.5-3.813-8.5-8.5z" /></svg>
                <div id='about'></div>

              </button>

            </div>
          </div>

          {/* Overview ends */}

          {/* about  */}

          <InsideHeading
            id="key-ingredients"
            keyDisplay="none"
            benefitDisplay="none"
            h3="About"
            p="Veinosil Forte Tablets are for conditions such as varicose veins, chronic venous insufficiency, hemorrhoids, diabetic retinopathy, and deep vein thrombosis."
            p3dis="none"
            p4dis="none"
            p5dis="none"

          />
          {/* <div id='key-ingredients'></div> */}

          {/* about ends */}

          {/* key ingredients  */}
          <InsideHeading
            id="how-to-use"
            benefitDisplay="none"
            h3="key Ingredients"
            pdis="none"
            p2dis="none"
            p3dis="none"
            p4dis="none"
            p5dis="none"
            key1="Calcium dobesilate 500 mg + Citrus bioflavonoids 500 mg + Vitamin C 100 mg"
            k2="none"
            k3="none"
            k4="none"
            k5="none"
            k6="none"
            k7="none"
            k8="none"
            k9="none"
            k10="none"
          />
          {/* key ingredients ends */}

          {/* how to use */}
          <InsideHeading
            id="benefits"
            keyDisplay="none"
            benefitDisplay="none"
            h3="How To Use"
            p="Use as mentioned on the label or directed by the physician."
            p4dis="none"
            p5dis="none"
            p3dis="none"
          />
          {/* how to use ends */}

          {/* how it works  */}
          {/* <InsideHeading
            benefitDisplay="none"
            keyDisplay="none"
            h3="How It Works"
            p="Axcelto tablet is a novel oral anticoagulant (NOAC). It works by stopping a clotting factor called
            factor Xa from working. This helps prevent the formation of blood clots in the body."
            p3dis="none"
            p4dis="none"
            p5dis="none"
          /> */}


          {/* how it works ends */}

          {/* benefits  */}
          <InsideHeading
            id="indications"
            keyDisplay="none"
            h3="Benefits"
            p="In Treatment and prevention of Blood clots"
            p2dis="none"
            p3dis="none"
            p4dis="none"
            p5dis="none"
            benefit1="It can be beneficial for problems like varicose veins."
            benefit2="It contains Calcium dobesilate as an active ingredient."
            benefit3="Calcium dobesilate acts as a potent antioxidant and prevents the action of highly
            damaging free radicals."
            b4="none"
            b5="none"
            b6="none"
            b7="none"
            b8="none"
            b9="none"
            b10="none"
          />
          {/* benefits ends */}

          {/* Indication  */}

          <div className="indications">
            <h3>Indications</h3>
            <div className="indications-content">
              <div className="indications-card">
                <div className="indications-img">
                  <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1704365827/Veinosil-Forte/Veinosil%20Tablet/Indications/Varicose_Veins_m8w8hz.webp" alt="Varicose Veins" />
                </div>
                <div className="indications-text">
                  <p>Varicose Veins</p>
                </div>
              </div>

              <div className="indications-card">
                <div className="indications-img">
                  <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1704365828/Veinosil-Forte/Veinosil%20Tablet/Indications/Hemorrhoids_cejtn3.webp" alt="Hemorrhoids" />
                </div>
                <div className="indications-text">
                  <p>Hemorrhoids</p>
                </div>
              </div>

              <div className="indications-card">
                <div className="indications-img">
                  <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1704365828/Veinosil-Forte/Veinosil%20Tablet/Indications/Platelet_Hyperaggregation_gzhyoe.webp" alt="Platelet Hyperaggregation" />
                </div>
                <div className="indications-text">
                  <p>Platelet Hyperaggregation</p>
                </div>
              </div>

              <div className="indications-card">
                <div className="indications-img">
                  <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1704365828/Veinosil-Forte/Veinosil%20Tablet/Indications/Chronic_Venous_Insufficiency_m9azym.webp" alt="Chronic venous insufficiency" />
                </div>
                <div className="indications-text">
                  <p>Chronic venous insufficiency</p>
                </div>
              </div>


              <div id='safety-information'></div>
            </div>

          </div>
          {/* indications ends */}

          {/* Safety  */}
          <div className="safety" >
            <h3>Safety Information</h3>
            <div className="safety-content">
              <div className="safety-card">
                <div className="safety-img">
                  <svg id="Layer_5" enable-background="new 0 0 62 62" height="512" viewBox="0 0 62 62" width="512" xmlns="http://www.w3.org/2000/svg"><g><g><path d="m30 32h14v14h-14z" fill="#f0bc5e" /></g><g><path d="m50 29h6v4h-6z" fill="#f0bc5e" /></g><g><path d="m50 21h5v4h-5z" fill="#f0bc5e" /></g><g><path d="m57.98 16.804c-.093-.468-.504-.804-.98-.804h-15c-2.206 0-4 1.794-4 4v8h-2v-13.46l-7-6v-2.54c1.103 0 2-.897 2-2 0-2.206-1.794-4-4-4h-10c-2.206 0-4 1.794-4 4 0 1.103.897 2 2 2v2.54l-7 6v13.46h-3c-.477 0-.887.336-.98.804l-4 20c-.059.294.018.599.207.83.19.232.473.366.773.366h7v7c0 2.757 2.243 5 5 5h18c2.757 0 5-2.243 5-5v-7h8c2.206 0 4-1.794 4-4v-8h13c.3 0 .583-.134.773-.366.189-.231.266-.536.207-.83zm-30.35-6.804 4.667 4h-20.594l4.667-4zm-4.63-4v2h-2v-2zm4 2h-2v-2h2zm-10-6h10c1.103 0 2 .897 2.001 2h-14.001c0-1.103.897-2 2-2zm2 4v2h-2v-2zm-9 10h24v12h-24zm24 41c0 1.654-1.346 3-3 3h-18c-1.654 0-3-1.346-3-3v-7h24zm12-11c0 1.103-.897 2-2 2h-41.78l3.6-18h38.18c.728 0 1.411-.195 2-.537zm-2-18h-4v-4.556c.59.344 1.268.556 2 .556h2c1.103 0 2 .897 2 2s-.898 2-2 2zm4 8v-10c0-2.206-1.794-4-4-4h-2c-1.103 0-2-.897-2-2s.897-2 2-2h14.18l3.6 18z" /><path d="m8 46h20v-14h-20zm2-12h16v10h-16z" /><path d="m30 32h2v2h-2z" /><path d="m34 32h10v2h-10z" /><path d="m30 36h14v2h-14z" /><path d="m30 40h14v2h-14z" /><path d="m30 44h4v2h-4z" /><path d="m36 44h8v2h-8z" /><path d="m50 32h6v2h-6z" /><path d="m50 28h6v2h-6z" /><path d="m50 24h5v2h-5z" /><path d="m50 20h5v2h-5z" /></g></g></svg>
                </div>
                <div className="safety-text">
                  <p>Read the label carefully before use</p>
                </div>
              </div>

              <div className="safety-card">
                <div className="safety-img">
                  <svg fill="none" height="512" viewBox="0 0 120 120" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m79 36c-13.2548 0-24 10.7452-24 24s10.7452 24 24 24 24-10.7452 24-24-10.7452-24-24-24z" fill="#a7baff"></path><g fill="#2140ae"><path d="m78 46c-2.2091 0-4 1.7909-4 4v11.3214l-2.5442-1.9788c-1.7438-1.3563-4.2569-1.0422-5.6131.7016-1.3563 1.7438-1.0422 4.2569.7016 5.6132l7.7721 6.045c2.1667 1.6852 5.2006 1.6852 7.3673 0l7.7721-6.045c1.7438-1.3563 2.0579-3.8694.7017-5.6132-1.3563-1.7438-3.8694-2.0579-5.6132-.7016l-2.5443 1.9789v-11.3215c0-2.2091-1.7909-4-4-4z"></path><path clip-rule="evenodd" d="m71 32.9163v-6.9163c0-8.8366-7.1634-16-16-16s-16 7.1634-16 16v36.5295c0 .6155-.2865 1.1931-.7569 1.5899-5.6526 4.7694-9.2431 11.9057-9.2431 19.8806 0 14.3594 11.6406 26 26 26 13.74 0 24.9907-10.658 25.9357-24.1578 13.5308-1.463 24.0643-12.9225 24.0643-26.8422 0-14.9117-12.0883-27-27-27-2.421 0-4.7675.3186-7 .9163zm-16-14.9163c-4.4183 0-8 3.5817-8 8v36.5295c0 3.1309-1.4506 5.8925-3.5979 7.7043-3.9282 3.3143-6.4021 8.2474-6.4021 13.7662 0 9.9411 8.0589 18 18 18 9.4272 0 17.1617-7.2471 17.9362-16.4741-1.7079-.324-3.3588-.809-4.9365-1.4384-.047 7.1394-5.8492 12.9125-12.9997 12.9125-7.1797 0-13-5.8203-13-13 0-5.7841 3.7775-10.6859 9-12.373v-11.627c0-9.3824 4.0796-18.1516 12-23.4534v-10.5466c0-4.4183-3.5817-8-8-8zm5 66c0 2.7614-2.2386 5-5 5s-5-2.2386-5-5 2.2386-5 5-5 5 2.2386 5 5zm18-44c-10.4934 0-19 8.5066-19 19s8.5066 19 19 19 19-8.5066 19-19-8.5066-19-19-19z" fill-rule="evenodd"></path><path d="m21 20c-2.2091 0-4 1.7909-4 4s1.7909 4 4 4h10c2.2091 0 4-1.7909 4-4s-1.7909-4-4-4z"></path><path d="m17 46c0-2.2091 1.7909-4 4-4h10c2.2091 0 4 1.7909 4 4s-1.7909 4-4 4h-10c-2.2091 0-4-1.7909-4-4z"></path><path d="m27 31c-2.2091 0-4 1.7909-4 4s1.7909 4 4 4h4c2.2091 0 4-1.7909 4-4s-1.7909-4-4-4z"></path><path d="m21 57c0-2.2091 1.7909-4 4-4h6c2.2091 0 4 1.7909 4 4s-1.7909 4-4 4h-6c-2.2091 0-4-1.7909-4-4z"></path></g></svg>
                </div>
                <div className="safety-text">
                  <p>Store in a cool dry place</p>
                </div>
              </div>

            </div>

          </div>
          {/* Safety ends */}

        </div>
      </div>

      <FooterInner />
    </div>
  )
}

export default Qmarco300
