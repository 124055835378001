import React from 'react'

const Sidebar = (props) => {

    return (
        <div className='sidebar'>
            <a style={{ display: props.overview }} href="#overview">
                Overview
                <svg id="Icons" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"><path d="m14.39 56.459c.953.994 2.235 1.541 3.611 1.541 1.295 0 2.522-.494 3.459-1.39l23.999-23c.979-.938 1.541-2.255 1.541-3.61s-.562-2.672-1.541-3.61l-24-23c-.967-.926-2.213-1.387-3.458-1.387-1.316 0-2.631.515-3.611 1.538-1.907 1.99-1.84 5.161.151 7.069l19.479 18.668c.196.188.308.449.308.722s-.111.533-.308.722l-19.479 18.668c-.965.925-1.512 2.169-1.54 3.504s.465 2.602 1.389 3.565z" /></svg>
            </a>
            <a style={{ display: props.about }} href="#about">
                About
                <svg id="Icons" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"><path d="m14.39 56.459c.953.994 2.235 1.541 3.611 1.541 1.295 0 2.522-.494 3.459-1.39l23.999-23c.979-.938 1.541-2.255 1.541-3.61s-.562-2.672-1.541-3.61l-24-23c-.967-.926-2.213-1.387-3.458-1.387-1.316 0-2.631.515-3.611 1.538-1.907 1.99-1.84 5.161.151 7.069l19.479 18.668c.196.188.308.449.308.722s-.111.533-.308.722l-19.479 18.668c-.965.925-1.512 2.169-1.54 3.504s.465 2.602 1.389 3.565z" /></svg>
            </a>
            <a style={{ display: props.keyingredients }} href="#key-ingredients">
                Key Ingredients
                <svg id="Icons" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"><path d="m14.39 56.459c.953.994 2.235 1.541 3.611 1.541 1.295 0 2.522-.494 3.459-1.39l23.999-23c.979-.938 1.541-2.255 1.541-3.61s-.562-2.672-1.541-3.61l-24-23c-.967-.926-2.213-1.387-3.458-1.387-1.316 0-2.631.515-3.611 1.538-1.907 1.99-1.84 5.161.151 7.069l19.479 18.668c.196.188.308.449.308.722s-.111.533-.308.722l-19.479 18.668c-.965.925-1.512 2.169-1.54 3.504s.465 2.602 1.389 3.565z" /></svg>
            </a>
            <a style={{ display: props.howtouse }} href="#how-to-use">
                How to Use
                <svg id="Icons" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"><path d="m14.39 56.459c.953.994 2.235 1.541 3.611 1.541 1.295 0 2.522-.494 3.459-1.39l23.999-23c.979-.938 1.541-2.255 1.541-3.61s-.562-2.672-1.541-3.61l-24-23c-.967-.926-2.213-1.387-3.458-1.387-1.316 0-2.631.515-3.611 1.538-1.907 1.99-1.84 5.161.151 7.069l19.479 18.668c.196.188.308.449.308.722s-.111.533-.308.722l-19.479 18.668c-.965.925-1.512 2.169-1.54 3.504s.465 2.602 1.389 3.565z" /></svg>
            </a>
            <a style={{ display: props.howitworks }} href="#how-it-works">
                How it works
                <svg id="Icons" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"><path d="m14.39 56.459c.953.994 2.235 1.541 3.611 1.541 1.295 0 2.522-.494 3.459-1.39l23.999-23c.979-.938 1.541-2.255 1.541-3.61s-.562-2.672-1.541-3.61l-24-23c-.967-.926-2.213-1.387-3.458-1.387-1.316 0-2.631.515-3.611 1.538-1.907 1.99-1.84 5.161.151 7.069l19.479 18.668c.196.188.308.449.308.722s-.111.533-.308.722l-19.479 18.668c-.965.925-1.512 2.169-1.54 3.504s.465 2.602 1.389 3.565z" /></svg>
            </a>
            <a style={{ display: props.benefits }} href="#benefits">
                Benefits
                <svg id="Icons" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"><path d="m14.39 56.459c.953.994 2.235 1.541 3.611 1.541 1.295 0 2.522-.494 3.459-1.39l23.999-23c.979-.938 1.541-2.255 1.541-3.61s-.562-2.672-1.541-3.61l-24-23c-.967-.926-2.213-1.387-3.458-1.387-1.316 0-2.631.515-3.611 1.538-1.907 1.99-1.84 5.161.151 7.069l19.479 18.668c.196.188.308.449.308.722s-.111.533-.308.722l-19.479 18.668c-.965.925-1.512 2.169-1.54 3.504s.465 2.602 1.389 3.565z" /></svg>
            </a>
            <a style={{ display: props.indications }} href='#indications'>
                Indications
                <svg id="Icons" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"><path d="m14.39 56.459c.953.994 2.235 1.541 3.611 1.541 1.295 0 2.522-.494 3.459-1.39l23.999-23c.979-.938 1.541-2.255 1.541-3.61s-.562-2.672-1.541-3.61l-24-23c-.967-.926-2.213-1.387-3.458-1.387-1.316 0-2.631.515-3.611 1.538-1.907 1.99-1.84 5.161.151 7.069l19.479 18.668c.196.188.308.449.308.722s-.111.533-.308.722l-19.479 18.668c-.965.925-1.512 2.169-1.54 3.504s.465 2.602 1.389 3.565z" /></svg>
            </a>
            <a style={{ display: props.storage }} href="#storage-instructions">
                Storage Instructions
                <svg id="Icons" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"><path d="m14.39 56.459c.953.994 2.235 1.541 3.611 1.541 1.295 0 2.522-.494 3.459-1.39l23.999-23c.979-.938 1.541-2.255 1.541-3.61s-.562-2.672-1.541-3.61l-24-23c-.967-.926-2.213-1.387-3.458-1.387-1.316 0-2.631.515-3.611 1.538-1.907 1.99-1.84 5.161.151 7.069l19.479 18.668c.196.188.308.449.308.722s-.111.533-.308.722l-19.479 18.668c-.965.925-1.512 2.169-1.54 3.504s.465 2.602 1.389 3.565z" /></svg>
            </a>
            <a style={{ display: props.safety }} href="#safety-information">
                Safety Information
                <svg id="Icons" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"><path d="m14.39 56.459c.953.994 2.235 1.541 3.611 1.541 1.295 0 2.522-.494 3.459-1.39l23.999-23c.979-.938 1.541-2.255 1.541-3.61s-.562-2.672-1.541-3.61l-24-23c-.967-.926-2.213-1.387-3.458-1.387-1.316 0-2.631.515-3.611 1.538-1.907 1.99-1.84 5.161.151 7.069l19.479 18.668c.196.188.308.449.308.722s-.111.533-.308.722l-19.479 18.668c-.965.925-1.512 2.169-1.54 3.504s.465 2.602 1.389 3.565z" /></svg>
            </a>

        </div>
    )
}

export default Sidebar
