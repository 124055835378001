import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './Components/Sass/main.scss'
import Home from './Components/Pages/Home';
import PrivacyPolicy from './Components/Pages/PrivacyPolicy'
import VeinosilForte from './Components/Pages/VeinosilForte';
import VeinosilCream from './Components/Pages/VeinosilCream';


function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products/veinosil-forte" element={<VeinosilForte />} />
          <Route path="/products/veinosil-cream" element={<VeinosilCream />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
